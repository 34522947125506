<template>
  <v-navigation-drawer :value='getDrawerVisibility' app clipped>
    <v-list nav dense>
      <v-list-item-group v-model='listModel' active-class='active-item'>
        <v-list-item v-if='isAdmin' :value='Route.DASHBOARD.name' @click='navigateTo(Route.DASHBOARD.name)'>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('DASHBOARD')}}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if='(isAdmin || isSupervisor)' :value='Route.HOME.name' @click='navigateTo(Route.HOME.name)'>
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('HOME')}}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if='(isAdmin || isSupervisor)' :value='Route.ENTRY.name' @click='navigateTo(Route.ENTRY.name)'>
          <v-list-item-icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('ENTRIES')}}</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item v-if='(isAdmin || isSupervisor)' :value='Route.ENTRY_MANUAL_INTERVENTION.name' @click='navigateTo(Route.ENTRY_MANUAL_INTERVENTION.name)'>
          <v-list-item-icon>
            <v-icon>mdi-account-alert-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('ENTRY_MANUAL_INTERVENTION')}}</v-list-item-title>
        </v-list-item> -->

        <!-- <v-list-item v-if='(isAdmin || isSupervisor)' :value='Route.ENTRY_LEGACY_IMPORT.name' @click='navigateTo(Route.ENTRY_LEGACY_IMPORT.name)'>
          <v-list-item-icon>
            <v-icon>mdi-database-import-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('IMPORT_LEGACY')}}</v-list-item-title>
        </v-list-item> -->

        <v-list-item v-if='isAdmin || hasMenu(Route.ENTRY_SEARCH.code)' :value='Route.ENTRY_SEARCH.name' @click='navigateTo(Route.ENTRY_SEARCH.name)'>
          <v-list-item-icon>
            <v-icon>mdi-account-search-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('ENTRY_SEARCH')}}</v-list-item-title>
        </v-list-item>

        <v-subheader v-if='isAdmin'>
          {{$t('ADMINISTRATION')}}
        </v-subheader>

        <v-list-item
          v-if='isAdmin'
          :value='Route.USERS.name'
          @click='navigateTo(Route.USERS.name)'
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('USERS')}}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isSuperAdmin'
          :value='Route.CUSTOMERS.name'
          @click='navigateTo(Route.CUSTOMERS.name)'
        >
          <v-list-item-icon>
            <v-icon>mdi-handshake-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('CUSTOMERS')}}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isAdmin'
          :value='Route.PERMISSION_GROUPS.name'
          @click='navigateTo(Route.PERMISSION_GROUPS.name)'
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('PERMISSION_GROUPS')}}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item
          v-if='isSuperAdmin'
          :value='Route.FEATURES.name'
          @click='navigateTo(Route.FEATURES.name)'
        >
          <v-list-item-icon>
            <v-icon> mdi-account-key-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('FEATURES')}}</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item v-if='isSuperAdmin' :value='Route.ADJUSTMENT_DUPLICATE_ENTRIES.name' @click='navigateTo(Route.ADJUSTMENT_DUPLICATE_ENTRIES.name)'>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('ADJUSTMENT_DUPLICATE_ENTRIES')}}</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item v-if='isSuperAdmin' :value='Route.RECORD_REUSAGE_REPORT.name' @click='navigateTo(Route.RECORD_REUSAGE_REPORT.name)'>
          <v-list-item-icon>
            <v-icon>mdi-clipboard-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('RECORD_REUSAGE_REPORT')}}</v-list-item-title>
        </v-list-item> -->

        <v-list-item v-if='isAdmin && userHasConfigurableService' :value='Route.SETTINGS.name' @click='navigateTo(Route.SETTINGS.name)'>
          <v-list-item-icon>
            <v-icon>mdi mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('SETTINGS')}}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if='isAdmin' :value='Route.LICENSE_MANAGER.name' @click='navigateTo(Route.LICENSE_MANAGER.name)'>
          <v-list-item-icon>
            <v-icon>mdi-application-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('LICENSE_MANAGER')}}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if='(isSuperAdmin || isAdmin)' :value='Route.RECORD_RECOVERY.name' @click='navigateTo(Route.RECORD_RECOVERY.name)'>
          <v-list-item-icon>
            <v-icon>mdi-account-convert</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('RECORD_RECOVERY')}}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template #append>
      <div class='pa-2'>
        <v-row v-if='logoUrl' justify='center' align='center' no-gutters>
          <v-img contain max-width='100' max-height='50' :src='openbioLogoUrl' />
        </v-row>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { Route } from '@/router';
  import { mapGetters } from 'vuex';

  export default {
    name: 'AppBarDrawer',
    data: function () {
      return {
        listModel: this.$router.currentRoute.name,
        Route: Route,
      };
    },
    watch: {
      $route: function () {
        this.listModel = this.$router.currentRoute.name || this.$route.name;
      },
    },
    mounted: async function () {
      this.listModel = this.$router.currentRoute.name || this.$route.name;
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      ...mapGetters('drawer', [
        'getDrawerVisibility',
      ]),
      ...mapGetters('customTheme', ['logoUrl', 'openbioLogoUrl']),
      isAdmin: function () {
        return this.getUser && this.getUser.account && this.getUser.account.role && (this.getUser.account.role.isAdmin || this.getUser.account.role.isSuperAdmin);
      },
      isSuperAdmin: function () {
        return this.getUser && this.getUser.account && this.getUser.account.role && this.getUser.account.role.isSuperAdmin;
      },
      isSupervisor: function () {
        return this.getUser && this.getUser.account && this.getUser.account.role && this.getUser.account.role.isSupervisor;
      },
      userHasConfigurableService: function () {
        const configurableServicesIds = [
          'Infant',
          'Openbio Enroll',
        ];
        return this.getUser?.services?.filter((service) => configurableServicesIds.includes(service.name));
      },
    },
    methods: {
      navigateTo: function (name) {
        if (this.$router.currentRoute.name !== name) {
          this.$router.push({ name: name });
        }
      },
      signOut: async function () {
        await this.$store.dispatch('auth/signOut');

        if (process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true') {
          window.location.assign(`${process.env.VUE_APP_SIGN_IN_URL}?redirectUrl=${btoa(window.location.href)}&origin=Management`);
        } else {
          this.$store.commit('auth/setSignInPageVisibility', true);
        }
      },
      hasMenu: function (menu) {
        if (this.getUser && this.getUser.account && this.getUser.account.menuCodes) {
          return this.getUser.account.menuCodes.find((menuCode) => menuCode === menu);
        }
        return false;
      },
    },
  };
</script>

<style>
  .active-item {
      background-color: var(--v-accent-base);
      color:var(--v-primary-base) !important;
    }

</style>
